<template>
  <v-container 
    fluid id="QualificacaoConsulta"
    class="container-fundo pa-0"
    v-bind:style="{ 'background-color':COR_PRINCIPAL }">

      <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">
        <!-- Título da página -->
        <v-toolbar-title class="text-white title-page mt-3 ml-4 "
          ><span v-if="!lb_Funcao">Minhas </span> Qualificações	
        </v-toolbar-title>
        <!-- Fim Título da página -->

        <!-- Botões de ação cabeçalho -->
        <v-toolbar-title
          class="mt-0 mb-6 mr-4 ml-4 pa-0"
          dark
          elevation="0"
        >
          <div class="d-flex " tabindex="-1">

          <!-- Text Field com Consulta ----------------------------------------->
          <v-container class="pa-0 ma-0 mb-7 d-flex " style="margin-top: -4px; max-width: 1200px;" >
              <v-menu
                id="vMenu"
                ref="vMenu"
                v-model="menu"
                bottom
                right
                fluid
                offset-y
                origin="top"
                class="pa-0 d-flex flex-wrap"
                elevation=0
                :close-on-content-click="false"
                @input="abriuConsulta"
              >
                <template v-slot:activator="{ on }">

                  <div
                    ref="vMenuDiv"
                    class="false-filter d-flex align-center"
                    align="center"
                    
                    v-on="on"
                  >
                    <v-icon class="false-icon ml-3 mr-2" color="#FFF">mdi-magnify</v-icon>

                    <div class="false-text flex-wrap" >
                      <v-chip
                      v-if="store_Qualificacao.filtro.cliente_nome"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      {{store_Qualificacao.filtro.cliente_nome}}
                    </v-chip>
                    <v-chip
                      v-if="store_Qualificacao.filtro.empreendimento"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      {{store_Qualificacao.filtro.empreendimento.empreend_nome}}
                    </v-chip>
                    <v-chip
                      v-if="store_Qualificacao.filtro.data_inicial"
                      pill
                      
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      >= {{store_Qualificacao.filtro.data_inicial}}
                    </v-chip>
                    <v-chip
                      v-if="store_Qualificacao.filtro.data_final"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      &#60;= {{store_Qualificacao.filtro.data_final}}
                    </v-chip>
                    <v-chip
                      v-if="store_Qualificacao.filtro.corretor"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                      >
                        {{store_Qualificacao.filtro.corretor.corretor_nome}}
                      </v-chip>

                      <v-chip
                        v-if="store_Qualificacao.filtro.situacao"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        {{store_Qualificacao.filtro.situacao}}
                      </v-chip>                                                                                                                        
                    </div>
                    <v-icon class="false-icon mr-4 ml-2" color="#FFF">mdi-filter-variant-plus</v-icon>
                  </div>
                </template>
                <v-container 
                  :style="{ 'background-color':COR_SECUNDARIA_2 }"
                  style="min-width:800px!important; max-width:1000px!important; margin-bottom: -34px;">
                  <v-row no-gutters class="linhas">
                    <v-col md="6">
                      <!-- Cliente ------------------------ -->
                      <v-text-field
                        id="txtCliente"
                        ref="txtCliente"
                        dense
                        v-model="filtro_local.cliente_nome"
                        cache-items
                        flat
                        label="Cliente"
                        placeholder="Cliente (nome ou cpf)..."
                        outlined
                        filled
                        background-color="#FFF"
                        clearable
                        tabindex="0"
                        class="mr-2"
                        autofocus 
                      >
                      </v-text-field>
                    </v-col>
                    <v-col md="6">
                      <!-- Empreendimento ------------------------ -->
                      <v-autocomplete
                        :items="empreendimentos"
                        v-model="filtro_local.empreendimento"
                        item-text="empreend_nome"
                        item-value="cod_empreendimento"
                        label="Empreendimento"
                        placeholder="Empreendimento"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        clearable
                        tabindex="0"
                        return-object
                        class="mr-2"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="linhas">
                    <v-col md="3" disabled>

                      <!-- Data Inicial ----------------------- -->
                      <DatePicker
                        id="txtVencInicial"
                        ref="txtVencInicial"
                        v-model="filtro_local.data_inicial"
                        label="Data Inicial"
                        placeholder="dia/mês/ano"
                        class="mr-2"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense>
                      </DatePicker>
                    </v-col>
                    <v-col md="3">
                      <!-- Data Final ----------------------- -->
                      <DatePicker
                        id="txtVencFinal"
                        ref="txtVencFinal"
                        v-model="filtro_local.data_final"
                        label="Data Final"
                        placeholder="dia/mês/ano"
                        class="mr-2"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense>
                      </DatePicker>
                    </v-col>  
                    <v-col md="6">
                      <v-autocomplete
                        v-if="lb_Funcao"
                        :items="corretores"
                        v-model="filtro_local.corretor"
                        item-text="corretor_nome"
                        item-value="cod_pessoa"
                        label="Corretor"
                        placeholder="Corretor"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        class="mt-n2 mr-n4"
                        style="padding-right: 6px!important;"
                        clearable
                        tabindex="4"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="linhas">
                    <v-col md="6">
                      <v-autocomplete
                        :items="array_situacao"
                        v-model="filtro_local.situacao"
                        label="Situação"
                        placeholder="Situação"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        class="mt-n5"
                        style="padding-right: 6px!important;"
                        clearable
                        tabindex="4"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
                <v-divider></v-divider>

                  <!-- Botao Limpar ------------------------------------>
                  <div class="pa-4 d-flex justify-space-between"
                       v-bind:style="{ 'background-color':COR_SECUNDARIA }">
                    <v-btn
                      class="text-white text-capitalize"
                      :color="COR_PRINCIPAL"
                      elevation="0"
                      outlined
                      tabindex="-1"
                      :small="isMobile"
                      @click="limpa_todos()"
                      >
                      <v-icon class="mr-1 icon-filter" color="light-blue accent-2"
                        >mdi-close</v-icon
                      >
                      <span>Limpar Todos</span>
                    </v-btn>
                    
                    <!-- Botao Consultar ------------------------------------>
                    <v-btn
                      class="text-white text-capitalize"
                      :color="COR_PRINCIPAL"
                      elevation="0"
                      tabindex="-1"
                      :small="isMobile"
                      @click="busca(1)"
                      >
                      <v-icon class="mr-1 icon-filter" color="light-blue accent-2"
                        >mdi-magnify</v-icon
                      >
                      <span>Consultar</span>
                    </v-btn>
                  </div>  

              </v-menu>
          </v-container>
          <!-- ----------------------------------------------- -->


          </div>
        </v-toolbar-title>
        <!-- Fim Botões de ação cabeçalho -->
      </v-toolbar-title>     
    
      <v-row class=" mt-n11 w-100 mx-0">
        <!-- Grade ----------------------------------->
        <v-col cols="12" class="pa-0 mx-0 w-100">
          <v-container class="container-principal container-rounded px-0 w-100 mx-0">

            <!-- Conteiner  -->
            <v-data-table
              id="dataTable"
              ref="dataTable"
              :items="store_Qualificacao.dados"
              :headers="headers"
              :loading="loading"
              fixed-header
              dense
              :height="tableHeight"
              :hide-default-header="isMobile"
              :hide-default-footer="true"
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado"
              class="data-table"
              :server-items-length="count_dados"
              :items-per-page="20"
              :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-arrow-left',
                    nextIcon: 'mdi-arrow-right',
                    'disable-items-per-page': true,
                    showItemsPerPage: false,
                    'items-per-page-text': '',
                    'rows-per-page-items':null
                  }"
            >
              <template #item="{ item }">
                <tr style="width: 15%" v-if="!isMobile">
                  <!-- <pre>{{ item }}</pre> -->

                  <td align="center">
                     <!-- Button Dots -->
                    <v-menu 
                      offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :color="item.situacao_cor"
                          class="text-none text-white"
                          rounded        
                          v-bind="attrs"
                          v-on="on"
                          style="font-size: 0.800rem;"
                        >
                          <v-icon v-if="item.fase_situacao != 'Aprovado'">
                            mdi-dots-vertical
                          </v-icon>
                          <v-icon v-else>mdi-check</v-icon>
                          {{ item.fase_situacao }}
                        </v-btn>
                      </template>
                       <v-list v-if="item.fase_situacao != 'Aprovado'">
                        <v-list-item
                          v-for="(item_menu, i) in items"
                          :key="i"
                          @click="() => {}"
                        >
                          <v-list-item-title
                            v-if="item_menu.title == 'Aprovar'"
                            @click="cod_opor_fase_selecionada = item.cod_opor_fase_etapa; cod_opor_fase_aux = item.cod_opor_fase; dialogConfirm = true"
                          >
                            <v-icon color="green" class="mr-2 icon-menu"
                              >mdi-check
                            </v-icon>
                            {{ item_menu.title }}
                          </v-list-item-title>

                          <v-list-item-title
                            v-else-if="item_menu.title == 'Reprovar'"
                            @click="cod_opor_fase_selecionada = item.cod_opor_fase_etapa; cod_opor_fase_aux = item.cod_opor_fase; dialogReprove = true; imagem_selecionada = ''"
                            ><v-icon color="red" class="mr-2 icon-menu"
                              >mdi-close</v-icon
                            >
                            {{ item_menu.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    
                    <!-- End Button Dots -->
                  </td>
                  <td align="center">
                    <span class="text font-weight-bold" style="font-size:16px" v-bind:style="{ 'color':COR_SUBTITULO }">Nº {{ item.cod_opor }}</span><br>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <div 
                          class="text-menu mb-1 mt-1"
                          v-bind="attrs"
                          v-on="on">
                          <v-avatar color="#C9C9C9" size="40">
                            <v-avatar
                              size="38"
                              style="top: 0px; left: 0px"
                            >
                              <v-img
                                v-if="item.corretor_foto"
                                :src="item.corretor_foto">
                              </v-img>
                              <span 
                                v-else
                                class="iniciais">
                                {{ nomeIniciais(item.corretor_nome) }}
                              </span>
                            </v-avatar>
                          </v-avatar><br>
                        </div>
                      </template>
                      <span class="font-weight-bold" style="font-size:13px; color:#FFFFFF !important;" >Corretor / Vendedor</span><br>
                      <span class="" style="font-size:12px; color:#F0F0F0 !important;" >{{ item.corretor_nome }}</span><br>
                      <span class="" style="font-size:12px; color:#F0F0F0 !important;" >{{ item.corretor_celular }}</span><br>                    
                      <span class="" style="font-size:12px; color:#F0F0F0 !important;" >{{ item.corretor_email }}</span>
                    </v-tooltip>

                        <div class="mt-n2" >
                          <span style="font-size:12px; color:#808080 !important;" >{{ formatDate(item.data_hora) }}</span><br>
                        </div>
                        <div class="mt-n2" >
                          <span style="font-size:12px; color:#808080 !important;" >{{TimeAgo(item.data_hora)}}</span>
                        </div>
                  </td>

                  <td style="width: 25%">
                    <span @click="pessoaCadastroMostrar(item)" class="text font-weight-bold mr-2" style="font-size:16px; cursor: pointer; text-decoration:underline" :style="{ 'color':COR_SUBTITULO }">{{item.cliente_nome}}</span><br>
                    <span class="mr-2" style="font-size:14px; color:#808080 !important;">Cpf:</span><span style="font-size:12px; color:#808080 !important;">{{ item.cliente_cpf }}</span><br>
                    <span class="mr-2" style="font-size:14px; color:#808080 !important;">Email:</span><span style="font-size:12px; color:#808080 !important;">{{ item.cliente_email }}</span><br>
                    <span class="mr-2" style="font-size:14px; color:#808080 !important;">Celular:</span><span style="font-size:12px; color:#808080 !important;">{{ item.cliente_celular }}</span>
                  </td>

                  <td style="width: 35%" class="py-1">
                    <v-row class="mt-3 mb-3">
                      <v-col style="min-width:80px; max-width:86px">
                        <v-avatar
                          v-if="item.imagem_caminho"
                          color="#C9C9C9"
                          size="82"
                          rounded>
                          <v-avatar
                            size="80"
                            rounded
                            style="top: 0px; left: 0px">
                            <v-img :src="item.imagem_caminho"></v-img>
                          </v-avatar>
                        </v-avatar>
                        <v-avatar
                          v-if="item.empreend_logo"
                          color="#FFFFFF"
                          size="82"
                          rounded>
                          <v-avatar
                            size="auto"
                            rounded
                            style="top: 0px; left: 0px">
                            <v-img v-bind:src="item.empreend_logo"></v-img>
                          </v-avatar>
                        </v-avatar>
                      </v-col>
                      <v-col>
                        <span class="font-weight-bold " style="color:#808080 !important;">{{ item.empreend_nome }}</span><br>
                        <span class="font-weight-bold " style="color:#808080 !important;">Quadra: </span>{{ item.quadra }}<span class="font-weight-bold ml-2 color:#808080 !important;"></span>
                        <span class="font-weight-bold " style="color:#808080 !important;">Lote:</span>{{ item.lote }}<br>
                        <span class="font-weight-bold " style="color:#808080 !important;">Área: </span>{{ item.area_metro }}<span class="font-weight-bold ml-2 color:#808080 !important;"></span>
                        <span class="font-weight-bold " style="color:#808080 !important;">Valor:</span>R$ {{ formatNumber(item.imovel_preco_final) }}<br>
                      </v-col>
                    </v-row>
                  </td>
                  <td style="width: 20%; text-align:right;" >
                    <div class="d-flex" style="justify-content: left">
                      <div class="pt-2" style="text-align: right; width:90px">
                        <v-btn
                          color="#2e8b57"
                          class="text-none"
                          style="font-size: 0.800rem;"
                          dark
                          rounded
                          outlined
                          @click="abre_documentos(item)"
                        >
                          <v-icon>
                            mdi-badge-account
                          </v-icon>
                          Documentação
                        </v-btn>
                      </div>
                    </div>
                  </td>
                </tr>

                <!-- MOBILE ------------------------------------------------------------------------------- -->
                <tr v-if="isMobile" class="table" style="background-color:#FFFFFF">
                  <td class="table-td pt-2 pb-3" style="background-color:#FFFFFF">
                    <div class="d-flex">
                      <div class="ml-n2 mb-2" style="width:100px" align="center">
                        <span class="text font-weight-bold" style="font-size:16px" v-bind:style="{ 'color':COR_SUBTITULO }">Nº {{ item.cod_empreendvenda }}</span><br>

                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <div>           
                              <!-- Button Dots -->
                              <!-- <v-menu offset-x>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    :color="item.situacao_cor"
                                    class="text-none text-white"
                                    rounded
                                    
                                    v-bind="attrs"
                                    v-on="on"
                                    style="font-size: 0.800rem;"
                                  >
                                    <v-icon>mdi-dots-vertical</v-icon>{{ item.fase_situacao }}
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(item_menu, i) in items"
                                    :key="i"
                                    @click="() => {}"
                                  >
                                    <v-list-item-title
                                      v-if="item_menu.title == 'Aprovar'"
                                      @click="cod_opor_fase_selecionada = item.cod_opor_fase_etapa;cod_opor_fase_aux = item.cod_opor_fase; dialogConfirm = true"
                                    >
                                      <v-icon color="green" class="mr-2 icon-menu"
                                        >mdi-check
                                      </v-icon>
                                      {{ item.cod_opor_fase_etapa }} - 
                                      {{ item_menu.title }}
                                    </v-list-item-title>

                                    <v-list-item-title
                                      v-else-if="item_menu.title == 'Reprovar'"
                                      @click="dialogReprove = true; imagem_selecionada = ''"
                                      ><v-icon color="red" class="mr-2 icon-menu"
                                        >mdi-close</v-icon
                                      >
                                      {{ item_menu.title }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu> -->
                              <!-- End Button Dots -->
                            </div>
                            <div 
                              class="text-menu mb-1 mt-0"
                              v-bind="attrs"
                              v-on="on">
                              <v-avatar color="#C9C9C9" size="40">
                                <v-avatar
                                  size="38"
                                  style="top: 0px; left: 0px"
                                >
                                  <v-img
                                    v-if="item.corretor_foto"
                                    :src="item.corretor_foto">
                                  </v-img>
                                  <span 
                                    v-else
                                    class="iniciais">
                                    {{ nomeIniciais(item.corretor_nome) }}
                                  </span>
                                </v-avatar>
                              </v-avatar><br>
                            </div>
                          </template>
                          <span class="font-weight-bold" style="font-size:13px; color:#FFFFFF !important;" >Corretor / Vendedor</span><br>
                          <span class="" style="font-size:12px; color:#F0F0F0 !important;" >{{ item.corretor_nome }}</span><br>
                          <span class="" style="font-size:12px; color:#F0F0F0 !important;" >{{ item.corretor_celular }}</span><br>                    
                          <span class="" style="font-size:12px; color:#F0F0F0 !important;" >{{ item.corretor_email }}</span>
                        </v-tooltip>

                        <div class="mt-n2" >
                          <span style="font-size:12px; color:#808080 !important;" >{{ formatDate(item.data_hora) }}</span><br>
                        </div>
                        <div class="mt-n2" >
                          <span style="font-size:12px; color:#808080 !important;" >{{TimeAgo(item.data_hora)}}</span>
                        </div>
                      </div>

                      <div class="ml-2">
                        <span class="text font-weight-bold mr-2" style="font-size:16px" v-bind:style="{ 'color':COR_SUBTITULO }">{{item.cliente_nome}}</span><br>
                        <span class="mr-2" style="font-size:14px; color:#808080 !important;">Cpf:</span><span style="font-size:12px; color:#808080 !important;">{{ item.cliente_cpf }}</span><br>
                        <span class="mr-2" style="font-size:14px; color:#808080 !important;">Email:</span><span style="font-size:12px; color:#808080 !important;">{{ item.cliente_email }}</span><br>
                        <span class="mr-2" style="font-size:14px; color:#808080 !important;">Celular:</span><span style="font-size:12px; color:#808080 !important;">{{ item.cliente_celular }}</span>
                      </div>
                    </div>

                  <!-- ================================================================ -->
                    <div class="d-flex">
                      <div style="width:100px">
                        <v-avatar color="#A9A9A9" size="82" rounded>
                          <v-avatar
                            size="80"
                            rounded
                            style="top: 0px; left: 0px"
                          >
                            <v-img :src="item.imagem_caminho"></v-img>
                          </v-avatar>
                        </v-avatar>
                      </div>

                      <div>
                        <span class="font-weight-bold ml-0 mobile-emp" style="color:#808080 !important;">{{ item.empreend_nome }}</span><br>
                        <span class="font-weight-bold ml-0" style="color:#808080 !important;">Quadra: </span>{{ item.quadra }}<span class="font-weight-bold ml-2 color:#808080 !important;"></span>
                        <span class="font-weight-bold mr-2 ml-0" style="color:#808080 !important;">Lote:</span>{{ item.lote }}<br>
                        <span class="font-weight-bold ml-0" style="color:#808080 !important;">Área: </span>{{ item.area_metro }}<span class="font-weight-bold ml-2 color:#808080 !important;"></span>
                        <span class="font-weight-bold mr-2 ml-0" style="color:#808080 !important;">Valor:</span>R$ {{ formatNumber(item.imovel_preco_final) }}<br>
                      </div>
                    </div>
                    <!-- ================================================================ -->

                    <div class="d-flex" style="justify-content: left">
                      <div class="pt-2" style="text-align: right; width:110px">
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Entrada:</span><br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Entr: Sinal:</span><br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Entr: Parc:</span><br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Parcelas:</span><br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Total:</span>
                      </div>
                      <div class="pt-2" style="text-align:right; width:150px">
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>R$ {{ formatNumber(item.entrada_total) }}<br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>{{ item.sinal }}<br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>{{ item.entrada }}<br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>{{ item.parcela }}<br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>R$ {{ formatNumber(item.valor_total) }}
                      </div>
                      <div class="td-edit" align="right" style="width:100px">
                       <v-btn
                          color="#2e8b57"
                          class="text-none"
                          style="font-size: 0.800rem;"
                          dark
                          rounded
                          outlined
                          @click="abre_documentos(item)"
                        >
                          <v-icon>
                            mdi-badge-account
                          </v-icon>
                          
                        </v-btn>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>

          </v-container>
        </v-col>
        <!-- Fim Grade ----------------------------------->
      </v-row>

      <!-- Paginacao ------------------------------ -->
      <div class="paginacao d-flex justify-end align-center" style="background-color: #FFFFFF;">
          <span style="color:grey; font-size:14px" class="mr-3">{{count_dados}} linhas</span>
          <v-pagination
            id="pagination"
            ref="pagination"
            v-model="currentPage"
            :length="store_Qualificacao.paginas"
            total-visible="5"
            @input="handlePageChange"
            aria-controls="dataTable"
            class="mt-1"
            style="color:grey;"
            />
        </div>

      <v-container v-if="dialog_documentos">
        <v-dialog
          v-model="dialog_documentos"
          persistent
          :retain-focus="false"
          height="600px"
          min-height="600px"
          max-width="600px"
        >
          <v-card class="justify-end align-center">
            <!-- @close="dialog_documentos = false"  // Executando funcão no Pai -->
            <!-- OU -->
            <!-- :dialog_documentos.sync="dialog_documentos"  // Outra opção para alterar a variavel do Pai -->
            <ModalEnvioDocumentos
            @close="dialog_documentos = false" />
          </v-card>
        </v-dialog>
      </v-container>

     <!-- Dialog Confirmação de Aprovação -->
    <v-dialog v-model="dialogConfirm" transition="dialog-bottom-transition" max-width="410" class="pa-0">
      <v-divider></v-divider>

      <v-card elevation="0" class="">
        <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
          <span class="text-white title-page body-1">Aprovar</span>
          <v-btn @click="dialogConfirm = false" icon dark color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title class="text-h6">
          Deseja realmente APROVAR esta fase?
        </v-card-title>
        <v-card-text>
         
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialogConfirm = false">
            Cancelar
          </v-btn>

          <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="acao()">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
          <!-- End Dialog Confirmação de Aprovação -->

       <!-- Dialog Confirmação de Reprovação -->
    <v-dialog v-model="dialogReprove" transition="dialog-bottom-transition" max-width="410" class="pa-0">
      <!-- <v-divider></v-divider> -->

      <v-card elevation="0" class="">
        <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
          <span class="text-white title-page body-1">Reprovar</span>
          <v-btn @click="dialogReprove = false" icon dark color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title class="text-h6">
          Deseja realmente REPROVAR esta fase?
        </v-card-title>
        <v-card-text>
         <v-container class="mt-4 px-0">
              <v-row>
                <v-col class="pb-0" cols="12">
                  <v-form ref="form" v-model="form">
                    <v-textarea 
                      rows="1" 
                      auto-grow 
                      counter="200" 
                      v-model="container.message" 
                      :rules="[rules.required]" 
                      class="input-razao mb-0" 
                      loader-height="1" 
                      autofocus
                      background-color="#FFF" 
                      label="Motivo da reprovação:" 
                      placeholder="Motivo da reprovação:" 
                      light 
                      filled 
                      outlined 
                      required 
                      clearable
                      dense></v-textarea>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialogReprove = false">
            Cancelar
          </v-btn>

          <v-btn class="btn white--text" color="primary accent-4" :disabled="!form" :loading="loading" @click="acaoReprovar()">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
          <!-- End Dialog Confirmação de Aprovação -->

  <!-- Dialogo de abetrtura dos dados do cliente Fisico -->
      <v-container
      v-if="store_Pessoa.dialog_cadastro_mobile"
      class="px-0">
      <v-dialog v-model="store_Pessoa.dialog_cadastro_mobile"
        max-width="440"
        :retain-focus="false"
        persistent>
        <v-card class="px-0" v-bind:style="{ 'background-color':COR_BACKGROUND }">
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
            class="justify-space-between py-2 px-3">
            <span class="text-white title-page body-1">Cadastro de Pessoas</span>
              <v-btn
                icon
                dark
                color="#F2F6F7"
                @click="store_Pessoa.dialog_cadastro_mobile = false; store_Pessoa.dialog_cadastro_juridica_mobile = false;">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-2 px-2">
              <PessoaCadastroMobile
                ref="PessoaCadastroMobile"
                :isMobileProp="true"
                :itemDataTable="item_aux"
                class="px-0"/>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>

      <!-- Dialogo de abetrtura dos dados do cliente Juridico-->
    <v-container
      v-if="store_Pessoa.dialog_cadastro_juridica_mobile"
      class="px-0">
      <v-dialog v-model="store_Pessoa.dialog_cadastro_juridica_mobile"
        max-width="440"
        :retain-focus="false"
        persistent>
        <v-card class="px-0" v-bind:style="{ 'background-color':COR_BACKGROUND }">
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
            class="justify-space-between py-2 px-3">
            <span class="text-white title-page body-1">Cadastro de Pessoas</span>
              <v-btn
                icon
                dark
                color="#F2F6F7"
                @click="store_Pessoa.dialog_cadastro_juridica_mobile = false">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-2 px-2">
              <PessoaCadastroJuridicaMobile
                ref="PessoaCadastroMobile"
                :isMobileProp="true"
                :itemDataTable="item_aux"
                class="px-0"/>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import Vue from "vue"
import { API, baseURL } from "../../../services/API"
import store_usuario from "../../../store/store_usuario"
import store_Qualificacao from "./store_Qualificacao";
import store_Corretor from "../../Corretores/store_Corretor";
import store_site from "../../../store/store_site"
import store_Pessoa from "../../Pessoas/store_Pessoa";
import { arraySIM_NAO, COR_BACKGROUND, COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO, COR_SECUNDARIA_2 } from "../../../services/constantes";
import { formatNumber, formatDate, nomeIniciais, TimeAgo } from "../../../services/funcoes";
import { OrcamentoRelatorio, ReservaRelatorio, PropostaRelatorio } from "../../../services/global"
import DatePickerComponent from "../../ComponentesGlobais/Date-Picker/date-picker-component.vue";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";

import ModalEnvioDocumentos from "../../ModalEnvioDocumentos/ModalEnvioDocumentos.vue"
import store_ModalEnvioDocumentos from "../../ModalEnvioDocumentos/store_ModalEnvioDocumentos"
import PessoaCadastroMobile from "../../Pessoas/PessoaCadastroMobile.vue";
import PessoaCadastroJuridicaMobile from "../../Pessoas/PessoaCadastroJuridicaMobile.vue";

export default {
  name: "QualificacoesConsulta",
  components: {
    // DatePickerComponent,
    DatePicker,
    ModalEnvioDocumentos,
    PessoaCadastroMobile,
    PessoaCadastroJuridicaMobile,
  },

  data() {
    return {
      store_usuario               : store_usuario,
      store_site                  : store_site,
      store_Qualificacao          : store_Qualificacao,
      store_Corretor              : store_Corretor,
      store_ModalEnvioDocumentos  : store_ModalEnvioDocumentos,
      store_Pessoa                : store_Pessoa,

      formatNumber        : formatNumber,
      formatDate          : formatDate,
      TimeAgo             : TimeAgo,
      nomeIniciais        : nomeIniciais,

      empreendimentos     : [],
      corretores          : [],

      dialog_documentos   : false,
      /* Dialog Menu Aprovar */
      dialogConfirm       : false,
      /* Dialog Menu Reprovar */
      dialogReprove       : false,
      /* Formulário Aprovação/Reprovação */
      form                : false,
      /* Container  */
      container: {
        receipt: "",
        message: "",
      },

      loading                     : false,
      loading_relatorio           : 0,
      lb_Funcao                   : false,

      currentPage                 : 1,
      count_dados                 : null,
      cod_opor_fase_selecionada   : null,
      cod_opor_fase_aux           : null,
      arraySIM_NAO                : arraySIM_NAO,
      item_aux                    : null,

      COR_PRINCIPAL               : COR_PRINCIPAL,
      COR_SUBTITULO               : COR_SUBTITULO,
      COR_SECUNDARIA              : COR_SECUNDARIA,
      COR_SECUNDARIA_2            : COR_SECUNDARIA_2,
      COR_BACKGROUND              : COR_BACKGROUND,
      accentColor                 : '#f00',

      menu                        : false,

       /* Menu Aprovação (Button Dots) */
      items: [
        {
          title: "Aprovar",
        },
        {
          title: "Reprovar",
        },
      ],

      array_situacao : [
        'Aguardando', 'Aprovado', 'Reprovado'
      ],

      // Filtro Local da Tela de Consulta --------
      filtro_local: {
        cliente_nome        : null,
        cod_corretor        : null,
        corretor            : null,
        cod_empreendimento  : null,
        empreendimento      : null,
        data_inicial        : null,
        data_final          : null,
        situacao               : null,
      },

      /* Título container-rounded */
      headers: [  { text: "", value: "Aprovação", sortable: true },
                  { text: "", value: "Negociação", sortable: true },
                  // { text: "Corretor", value: "Corretor", sortable: true },
                  { text: "Cliente", value: "Cliente", sortable: true },
                  { text: "Empreendimento / Unidade", value: "Empreendimento/Unidade", sortable: true },
                  { text: "Etapa", value: "Etapa", sortable: true },
               ],

      rules: {
        required: (v) => !!v || "Preencha o motivo para continuar",
      },

      data_picker : {
        'min-width':'170px !important',
        //'max-width':'180px !important',
        //'margin-right':'8px !important',
        'margin-top':'-10px !important',
        //'color':'red'
      }

    };
  },

  async created() {
    this.lb_Funcao = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
    if (this.lb_Funcao)
      this.busca_Corretores();
    this.busca(1);
    this.getEmpreendimento();

  },

  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 70;
      else
        return window.innerHeight - 149 - 70;
    }

  },

  mounted() {
    console.log('mounted');
    // onResize();
    // window.addEventListener("resize", this.onResize);
  },

  // destroyed() {
    // window.removeEventListener("resize", this.onResize);
  // },
  
  methods: {
    async limpa_todos() {
      for(let prob in this.filtro_local){
        //console.log('limpando: ' , this.filtro_local[prob])
        this.filtro_local[prob] = null
      }
    },
    async pessoaCadastroMostrar(item) {
      var lo_params = { cod_pessoa: item.cod_cliente };
      const lo_Res = await this.store_Pessoa.PessoaGet(lo_params);
      if ( lo_Res.rows.length > 0) {
        this.store_Pessoa.pessoa_edicao = { ...lo_Res.rows[0] };
      }
      this.store_Pessoa.acao = 'E';
      this.item_aux = item

      // console.log('itens', this.item_aux, item);

      if(this.store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === "Física"){
        this.store_Pessoa.dialog_cadastro_mobile = true;
      }
      else{
        this.store_Pessoa.dialog_cadastro_juridica_mobile = true
      }

    },

    async getEmpreendimento() {
      const resp = await API.get(`empreendimento`);
      if (resp) {
        var la_Resp = resp.data.result;
        this.empreendimentos = la_Resp;
      }
    },

    async busca_Corretores() {
      var lo_params = {};
      this.corretores = await this.store_Corretor.CorretoresGet(lo_params);
    },

    handlePageChange(value) {
      this.currentPage = value;
      this.busca(value);
    },

    async busca(page) {
      this.loading = true;
      this.menu    = false;
      this.store_Qualificacao.filtro = {...this.filtro_local};
     
      if (this.store_Qualificacao.filtro.cliente_nome) {
        this.store_Qualificacao.filtro.cliente_nome = this.store_Qualificacao.filtro.cliente_nome.trim()
      }
      var lo_params = { page: page, ...this.store_Qualificacao.filtro };
      // Limpando o objeto Empreendimento para enviar a API e deixando somente os codigos
      lo_params.cod_empreendimento   = null;
      if (this.store_Qualificacao.filtro.empreendimento) {
        lo_params.cod_empreendimento = this.store_Qualificacao.filtro.empreendimento.cod_empreendimento;
        lo_params.empreendimento     = null;
      }
      // Limpando o objeto Corretor para enviar a API e deixando somente os codigos
      lo_params.cod_corretor   = null;
      if (this.store_Qualificacao.filtro.corretor) {
        lo_params.cod_corretor = this.store_Qualificacao.filtro.corretor.cod_pessoa;
        lo_params.corretor     = null;
      }
      if (!this.lb_Funcao) {
        lo_params.cod_corretor = store_usuario.user.cod_pessoa;
      }

      let la_JSON
      console.log('params', lo_params);
      la_JSON = await this.store_Qualificacao.QualificacoesGet(lo_params);
      this.count_dados = la_JSON.count;
      if (la_JSON.rows && la_JSON.rows.length > 0)  {
        la_JSON.rows.map((value) => {
          value.imagem_caminho  = value.imagem_caminho.indexOf('null') < 0 ? baseURL + value.imagem_caminho : null;
          value.corretor_foto   = value.corretor_foto ? baseURL + value.corretor_foto : null;
          if (value.fase_situacao == 'Aprovado')        value.situacao_cor    = "#93b649";
          else if (value.fase_situacao == 'Reprovado')  value.situacao_cor    = "#d00000";
          else if (value.fase_situacao == 'Aguardando') value.situacao_cor    = "#c0c0c0";
        })
        this.store_Qualificacao.dados   = la_JSON.rows;
        this.store_Qualificacao.paginas = la_JSON.paginas;
      }
      else
        this.store_Qualificacao.dados = [];
      this.loading = false;

      // Posiciona o Scrool no começo da tabela 
      try {
        this.$vuetify.goTo('.data-table tbody tr',
                            { offset: 60,
                              container: '.v-data-table__wrapper'
                            }
                          )
      }
      catch (e) {
      }
      
    },

    async qualificacaoRelatorio(cod_opor) {
      this.loading_relatorio = cod_opor;
      const ls_Resp = await QualificacaoRelatorio(cod_opor);
      this.loading_relatorio = 0;
    },

    abriuConsulta(val) {
      if (val) {
        this.$nextTick(() => {
          this.filtro_local = {...this.store_Qualificacao.filtro};
        });
      }
    },

    abre_documentos(item) {
      this.store_ModalEnvioDocumentos.cod_pessoa_aux = item.cod_cliente
      this.store_ModalEnvioDocumentos.cod_opor_aux = item.cod_opor
      this.store_ModalEnvioDocumentos.item_aux = item
      this.store_ModalEnvioDocumentos.dados = {};
      this.store_ModalEnvioDocumentos.Abre_Documentos(item.cod_cliente, item.cliente_cod_pessoa_socio, item.cliente_cod_pessoa_conjuge);
      this.dialog_documentos = true;
    },
    async acaoReprovar(){
      this.loading = true;
      const ls_JSON = {cod_opor_fase_etapa: this.cod_opor_fase_selecionada, acao : 'Reprovado', situacao_motivo: this.container.message}
      const ls_Resp = await this.store_Qualificacao.QualificacaoAcao(ls_JSON);

     if (ls_Resp.status == 200) {
        this.store_site.alert_cor = "green";
        this.store_site.alert_type = "success";
        this.store_site.alert_timeout = 10000;
        this.store_site.alert_msg       = 'Fase Reprovada com Sucesso !';
        this.store_site.alert           = true;
        this.loading                    = false;
        
        const la_JSON = await this.store_Qualificacao.QualificacoesGet({cod_opor_fase: this.cod_opor_fase_aux});
        la_JSON.rows.map((value) => {
          value.imagem_caminho  = value.imagem_caminho.indexOf('null') < 0 ? baseURL + value.imagem_caminho : null;
          value.corretor_foto   = value.corretor_foto ? baseURL + value.corretor_foto : null;
          if (value.fase_situacao == 'Aprovado')        value.situacao_cor    = "#93b649";
          else if (value.fase_situacao == 'Reprovado')  value.situacao_cor    = "#d00000";
          else if (value.fase_situacao == 'Aguardando') value.situacao_cor    = "#c0c0c0";
        })
        const la_Posicao_Array = this.store_Qualificacao.dados.findIndex((o) => (o.cod_opor_fase === this.cod_opor_fase_aux ))
        Vue.set(this.store_Qualificacao.dados, la_Posicao_Array, la_JSON.rows[0])
        this.dialogReprove = false;
      }
      else {
        this.store_site.alert_cor       = "#FF0000",
        this.store_site.alert_timeout   = 10000,
        this.store_site.alert_msg       = ls_Resp.result.trim();
        this.store_site.alert           = true;
        this.loading                    = false;
      }      
    },
    async acao() {
      this.loading = true
      const ls_JSON = {cod_opor_fase_etapa: this.cod_opor_fase_selecionada, acao : 'Aprovado'}
      const ls_Resp = await this.store_Qualificacao.QualificacaoAcao(ls_JSON);

      if (ls_Resp.status == 200) {
        this.store_site.alert_cor = "green";
        this.store_site.alert_type = "success";
        this.store_site.alert_timeout = 10000;
        this.store_site.alert_msg       = 'Fase Aprovada com Sucesso !';
        this.store_site.alert           = true;
        this.loading                    = false;
        
        const la_JSON = await this.store_Qualificacao.QualificacoesGet({cod_opor_fase: this.cod_opor_fase_aux});
        la_JSON.rows.map((value) => {
          value.imagem_caminho  = value.imagem_caminho.indexOf('null') < 0 ? baseURL + value.imagem_caminho : null;
          value.corretor_foto   = value.corretor_foto ? baseURL + value.corretor_foto : null;
          if (value.fase_situacao == 'Aprovado')        value.situacao_cor    = "#93b649";
          else if (value.fase_situacao == 'Reprovado')  value.situacao_cor    = "#d00000";
          else if (value.fase_situacao == 'Aguardando') value.situacao_cor    = "#c0c0c0";
        })
        const la_Posicao_Array = this.store_Qualificacao.dados.findIndex((o) => (o.cod_opor_fase === this.cod_opor_fase_aux ))
        Vue.set(this.store_Qualificacao.dados, la_Posicao_Array, la_JSON.rows[0])
        this.dialogConfirm = false;

      }
      else {
        this.store_site.alert_cor       = "#FF0000",
        this.store_site.alert_timeout   = 10000,
        this.store_site.alert_msg       = ls_Resp.result.trim();
        this.store_site.alert           = true;
        this.loading                    = false;
      }
    },

    pagination() {
      return { totalItems: 100 }
    },

    // EXEMPLO
        // onResize() {
        //   if (this.isMobile)
        //     this.tableHeight = window.innerHeight - this.$refs.toolbar.clientHeight + 30 - 90;
        //   else
        //     this.tableHeight = window.innerHeight - this.$refs.toolbar.clientHeight - 90;

        //   if (window.innerWidth < 769)
        //     this.isMobile = true;
        //   else
        //     this.isMobile = false;
        // },
        // toggleAll() {
        //   if (this.selected.length) this.selected = []
        //   else this.selected = this.desserts.slice()
        // },
        // changeSort(column) {
        //   console.log(column);
        //   if (this.pagination.sortBy === column) {
        //     this.pagination.descending = !this.pagination.descending
        //   } else {
        //     this.pagination.sortBy = column
        //     this.pagination.descending = false
        //   }
        // }

  },
};
</script>

<style scoped>
.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.linhas {
    height:55px;
    background-color:transparent;
  }

.date-picker {
  max-width: 200px!important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

.v-btn {
  display: inline-block;
}

/*-----------------------------------*/
/*------ RESPONSIVE GENERAL ------*/
/*-----------------------------------*/
.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.v-menu__content {
    z-index: 10!important;
}

.container-fundo {
  height: calc(100vh -30px) !important;
}

.container-rounded {
  background: #fff !important;
  border-radius: 12px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

#virtual-scroll-table-tablet {
  display: none;
}

.table {
  display: block;
  table-layout: fixed;
  overflow: hidden;
  width: 100% !important;
  min-height: 300px !important;
}

.table-td {
  display: block;
  table-layout: fixed;
  overflow: hidden;
  width: 100% !important;
  min-height: 330px !important;
}

.card-container {
  padding: 16px!important;
}

.false-filter {
  background-color:#6C87A2;
  height: 38px!important;
  min-width: 400px!important;
  /* max-width: 900px!important; */
  border-radius: 5px;
}

.false-text {
  width: calc(100%);
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  color: #C9CBCA;
  cursor: pointer;
  overflow-x: hidden;
}

.row {
  width: 100%!important;
}

.false-icon {
  cursor: pointer;
}

.v-select__slot {
  display: none !important;
}

.paginacao {
  padding: 2px;
}

/* ::v-deep .v-data-footer {
  align-content: center;
  color: var(--COR_PRINCIPAL) !important;  
  font-size:14px !important;
  height: 35px;
  padding-top: 10px !important;
}

::v-deep .v-data-footer__select {
  display: none;
} */

.theme--light.v-data-table {
  border-radius: 12px 12px 0px 0px !important;
}
@media(max-width: 768px) {
  .container_btn-header .caption {
    margin-top: 12px !important;
  }
}
@media(max-width: 599px) {
 
  .container_btn-header .caption {
    margin-top: 16px !important;
  }
}

</style>